import * as React from "react";
import { Logo } from "./svg";

const Footer = () => {
  return (
    <div className="bg-blue-800">
      <div className="container">
        <footer className="flex flex-wrap justify-between items-center text-white text-xl">
          <Logo className="w-auto sm:h-14 h-12 text-white fill-current" />
          <p className="py-4">
            <span className="mr-4">Contact us:</span> <span className="mr-4">+372 6565 600</span> <a href="mailto:info@levercode.com">info@levercode.com</a>
          </p>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
