import * as React from "react";
import { Logo } from "./svg";
import Navigation from "./Navigation";

const SubPageHeader = () => (
  <div className="container max-w-screen-3xl pb-12 mt-2 sm:mt-0">
    <header className="flex justify-between items-center">
      <a href="/">
        <Logo className="w-auto sm:h-14 h-12 ml-2 sm:ml-0 text-blue-800 fill-current" />
      </a>
      <Navigation />
    </header>
  </div>
);

export default SubPageHeader;
