import * as React from "react";
import HomePageHeader from "../components/HomePageHeader";
import SubPageHeader from "../components/SubPageHeader";
import Footer from "../components/Footer";

const Layout = ({ isHomepage, children }) => (
  <main className={isHomepage ? "bg-gray-100" : "bg-white" + " border-t-4 border-blue-800"}>
    {isHomepage ? <HomePageHeader /> : <SubPageHeader />}
    {children}
    <Footer />
  </main>
);

export default Layout;
