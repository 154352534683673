import * as React from "react";
import Navigation from "./Navigation";
import { Logo } from "./svg";

const HomePageHeader = () => (
  <>
    <div className="bg-blue-800 _hero">
      <div className="container max-w-screen-3xl">
        {/* header */}
        <header className="flex justify-between items-center mt-2 sm:mt-0">
          <Logo className="w-auto h-12 sm:h-14 ml-2 sm:ml-0 text-white fill-current" />

          <Navigation isHomePage />
        </header>

        {/* hero */}

        <div className="mx-auto py-12 sm:py-56 text-white">
          <h1 className="text-4xl sm:text-7xl leading-normal font-bold py-8 text-center">
            Blueprint for{" "}
            <span className="whitespace-nowrap">
              <span className="text-yellow-800 text-5xl sm:text-8xl">[</span> Data <span className="text-yellow-800 text-5xl sm:text-8xl">]</span>
            </span>{" "}
            Governance
          </h1>
          <p className="sm:px-2/12 text-xl sm:text-3xl leading-relaxed text-center pb-24">Reliable, sustainable, and low risk data governance systems</p>
        </div>
      </div>
    </div>
  </>
);

export default HomePageHeader;
