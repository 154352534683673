import * as React from "react";

export const Logo = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251 56">
    <path
      fillRule="evenodd"
      d="M250.337 33.915v3.438h-12.944V19.479h12.944v3.437h-8.856v4.125h7.493v2.75h-7.493v4.124h8.856zm-25.888 3.438h-6.13V19.479h6.13c7.857 0 9.538 4.29 9.538 8.937 0 5.161-2.046 8.937-9.538 8.937zm0-14.437h-2.724v10.999h2.724c4.142 0 5.451-1.666 5.451-5.499 0-4.277-2.095-5.5-5.451-5.5zm-20.702 11.44h4.285V22.341h-4.285v-4.292h8.57v20.598h-8.57v-4.291zm-13.714-16.307h8.571v4.292h-4.285v12.015h4.285v4.291h-8.571V18.049zm-14.854 15.396c.866.489 1.853.734 2.958.734 1.784 0 3.28-.712 4.487-2.135l2.651 2.47a8.272 8.272 0 01-3.161 2.392c-1.258.548-2.661.823-4.207.823-1.819 0-3.464-.399-4.934-1.196a8.89 8.89 0 01-3.467-3.319c-.841-1.415-1.262-3.015-1.262-4.798 0-1.784.421-3.383 1.262-4.798a8.886 8.886 0 013.467-3.32c1.47-.798 3.124-1.196 4.96-1.196 1.546 0 2.944.274 4.193.824a8.401 8.401 0 013.149 2.366l-2.651 2.47c-1.207-1.406-2.703-2.109-4.487-2.109-1.105 0-2.092.244-2.958.733a5.19 5.19 0 00-2.027 2.045c-.484.875-.727 1.87-.727 2.985 0 1.115.243 2.11.727 2.984a5.184 5.184 0 002.027 2.045zm-13.403 3.908l-4.087-6.187h-1.363v6.187h-4.087V19.479h6.13c4.9 0 6.328 2.639 6.132 5.5.196 2.007-1.063 4.234-3.406 4.812.375.425 2.418 2.946 6.131 7.562h-5.45zm-4.768-14.437h-1.363v5.5h1.363c2.284 0 3.406-.885 3.406-2.75 0-1.811-.646-2.75-3.406-2.75zm-21.801-3.437h12.944v3.437h-8.856v4.125h7.493v2.75h-7.493v4.124h8.856v3.438h-12.944V19.479zm-12.942 17.874l-6.813-17.874h4.087l4.769 12.374 4.087-12.374h4.088l-6.812 17.874h-3.406zM99.783 19.479h12.944v3.437h-8.856v4.125h7.493v2.75h-7.493v4.124h8.856v3.438H99.783V19.479zm-14.987 0h4.088v14.436h8.174v3.438H84.796V19.479zM16.992 55.997L.665 27.996 16.992-.005h32.342L65.38 27.996 49.334 55.997H16.992zM46.79 4.404H19.515L5.759 27.996l13.756 23.593H46.79l13.518-23.593L46.79 4.404zM33.033 42.152l3.924-6.292h7.616l-3.924 6.292h-7.616zm-12.588 0l16.166-27.263h7.962L28.648 42.152h-8.203z"
    />
  </svg>
);

export const Check = () => (
  <svg className="w-auto h-8 sm:h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <path fillRule="evenodd" fill="#FFBE00" d="M20-.001c11.046 0 20 8.954 20 20s-8.954 20-20 20-20-8.954-20-20 8.954-20 20-20z" />
    <path
      fillRule="evenodd"
      fill="#FFF"
      d="M17.44 25.647c.146.166.334.25.563.25a.713.713 0 00.562-.25l9.22-9.188a.764.764 0 00.218-.562.764.764 0 00-.218-.563l-1.157-1.125a.696.696 0 00-.547-.25.794.794 0 00-.578.25l-7.5 7.5-3.5-3.5a.794.794 0 00-.578-.25.694.694 0 00-.547.25l-1.157 1.125a.762.762 0 00-.218.563c0 .229.072.417.218.562l5.219 5.188z"
    />
  </svg>
);

export const FocusAreaIcon = ({ icon, className }) => {
  if (icon === "government") {
    return (
      <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222 192">
        <path fillRule="evenodd" d="M55.583.168l110.88-.17 55.292 95.834-55.587 96.004-110.879.17L-.004 96.172 55.583.168z" />
        <path
          fillRule="evenodd"
          fill="#FFF"
          d="M110.848 56.031a1 1 0 00-.375.156L73.476 79.139a1 1 0 00-.469.843v5.028c0 .552.448.999 1 1H148a1 1 0 001-1v-5.028a1.001 1.001 0 00-.468-.843l-36.997-22.952a1.005 1.005 0 00-.687-.156zm.156 2.186l35.997 22.328v3.466H75.007v-3.466l35.997-22.328zm0 10.555c-2.59 0-4.719 2.128-4.719 4.715 0 2.588 2.129 4.715 4.719 4.715 2.589 0 4.718-2.127 4.718-4.715 0-2.587-2.129-4.715-4.718-4.715zm0 1.998a2.709 2.709 0 012.718 2.717 2.708 2.708 0 01-2.718 2.717 2.709 2.709 0 01-2.719-2.717 2.71 2.71 0 012.719-2.717zM81.881 89.632a.998.998 0 00-.874 1.03v25.326h-7a.737.737 0 00-.094 0 1 1 0 00-.906.999v3.997h-3.999c-.553 0-1 .447-1 .999v6.995a1 1 0 101.999 0v-5.995h4a1 1 0 001-1v-3.997h71.994v3.997a1 1 0 00.999 1h4v5.995a1 1 0 002 .029v-7.024a1 1 0 00-1-.999h-4v-3.997a.999.999 0 00-1-.999h-6.999V90.662a.999.999 0 00-1.125-1.03 1 1 0 00-.875 1.03v25.326h-5.999V90.662a1 1 0 00-1.125-1.03 1 1 0 00-.875 1.03v25.326h-14.999V90.662a.999.999 0 00-1.125-1.03.998.998 0 00-.874 1.03v25.326h-6V90.662a.999.999 0 00-1.125-1.03 1 1 0 00-.875 1.03v25.326H91.006V90.662a1 1 0 10-2 0v25.326h-6V90.662a.999.999 0 00-1.125-1.03z"
        />
      </svg>
    );
  } else if (icon === "healthcare") {
    return (
      <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222 192">
        <path fillRule="evenodd" d="M55.583.168l110.88-.17 55.292 95.834-55.587 96.004-110.879.17L-.004 96.172 55.583.168z" />
        <path
          stroke="#FFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          d="M93.416 59.951c-7.211 0-13.707 3.53-18.232 9.041-.018.022-.042.038-.059.06-7.184 8.452-7.766 21.545-1.362 31.867.005.009-.004.022 0 .029 13.75 23.065 31.107 29.424 36.732 31.008.329.091.677.091 1.006 0 5.625-1.584 22.982-7.943 36.731-31.008.005-.009-.004-.021 0-.029 6.404-10.322 5.822-23.415-1.361-31.867-.013-.016-.016-.043-.03-.06h-.029c-4.526-5.511-11.021-9.041-18.233-9.041-6.889 0-12.619 3.572-17.581 7.767-4.962-4.195-10.692-7.767-17.582-7.767zm-12.22 35.061c.038.255.127.5.263.719 6.18 10.374 13.124 17.397 19.387 22.115"
        />
      </svg>
    );
  } else if (icon === "fintech") {
    return (
      <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222 192">
        <path fillRule="evenodd" d="M55.583.168l110.88-.17 55.292 95.834-55.587 96.004-110.879.17L-.004 96.172 55.583.168z" />
        <path stroke="#FFF" strokeWidth="2" fill="none" d="M74.002 95.996c0 20.402 16.596 37.002 36.996 37.002 20.4 0 36.996-16.6 36.996-37.002 0-20.404-16.596-37.003-36.996-37.003-20.4 0-36.996 16.599-36.996 37.003z" />
        <path
          fillRule="evenodd"
          fill="#FFF"
          d="M122.455 104.336a1.51 1.51 0 010 2.133 14.687 14.687 0 01-6.223 3.652v4.369c0 .832-.68 1.506-1.518 1.506a1.512 1.512 0 01-1.518-1.506v-3.772c-.449.042-.899.066-1.354.066a15.21 15.21 0 01-1.822-.114v3.82c0 .832-.679 1.506-1.517 1.506a1.511 1.511 0 01-1.518-1.506v-4.537c-5.97-2.083-9.971-7.671-9.988-13.951.014-6.287 4.021-11.883 10-13.963v-4.525c0-.832.679-1.507 1.518-1.507.838 0 1.518.675 1.518 1.507v3.821c1.049-.134 2.11-.15 3.163-.048v-3.773c0-.832.68-1.507 1.518-1.507s1.518.675 1.518 1.507v4.363a14.663 14.663 0 016.223 3.67 1.498 1.498 0 01-.04 2.13 1.526 1.526 0 01-2.109-.003 11.755 11.755 0 00-5.902-3.152 11.477 11.477 0 00-5.501.091 11.93 11.93 0 00-5.373 3.061 11.719 11.719 0 00.017 16.661 11.882 11.882 0 005.35 3.05c1.786.477 3.663.51 5.465.097a11.678 11.678 0 005.944-3.146 1.526 1.526 0 012.147-.002l.002.002z"
        />
      </svg>
    );
  } else {
    return null;
  }
};
