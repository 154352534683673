import * as React from "react";
import { useState } from "react";
import { Link } from "gatsby";

const Navigation = ({ isHomePage }) => {
  /* Homepage nav links are white, subpage links are gray. On mobile view both are white */

  let menuColorClass;

  if (isHomePage) {
    menuColorClass = "text-white";
  } else {
    menuColorClass = "text-white sm:text-gray-800";
  }

  /* Mobile view navigation show & hide and svg icon color changes */

  const [isMenu, setMenu] = useState(false);

  const toggleMenu = (e) => {
    if (isMenu) {
      setMenu(false);
    } else {
      setMenu(true);
    }
  };

  return (
    <>
      <button onClick={toggleMenu} className="block h-12 fixed right-0 z-50 mr-6 sm:hidden focus:outline-none">
        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="48" className="text-yellow-800 fill-current">
          <path d="M.008 23.997L13.883-.005h27.485l13.636 24.002-13.636 24.001H13.883L.008 23.997z" />
          {/*burger ico*/}
          <path className={isMenu ? "hidden" : "block" + " text-white"} d="M20 30v-4h16v4H20zm0-12h16v4H20v-4z" />
          {/*close ico*/}
          <path className={!isMenu ? "hidden" : "block" + " text-white"} d="M35.009 28.906l-2.103 2.102L28 26.102l-4.906 4.906-2.103-2.102L25.897 24l-4.905-4.906 2.102-2.103L28 21.897l4.906-4.906 2.102 2.103L30.103 24l4.906 4.906z" />
        </svg>
      </button>

      <div className={!isMenu ? "hidden sm:block" : "block" + " z-40 sm:z-auto fixed sm:relative inset-0 sm:inset-auto bg-blue-800 sm:bg-transparent"}>
        <ul className={menuColorClass + " text-white flex flex-col sm:flex-row items-center mt-48 sm:mt-0"}>
          <li className="uppercase font-bold sm:ml-10 block sm:hidden">
            <Link to="/" className="_nav inline-block p-2">
              Home
            </Link>
          </li>
          {navigationData.map(function (data) {
            return (
              <li key={data.id} className="uppercase font-bold sm:ml-10">
                <Link to={data.url} className="_nav inline-block p-2" activeClassName="text-yellow-800 sm:text-blue-800">
                  {data.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

const navigationData = [
  { id: 0, title: "e-Governance", url: "/expertise/government" },
  { id: 1, title: "Digital Health", url: "/expertise/healthcare" },
  { id: 2, title: "Fintech", url: "/expertise/fintech" },
  { id: 3, title: "About", url: "/about" },
  { id: 4, title: "Contact", url: "/contact" },
];

export default Navigation;
